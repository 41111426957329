import { template as template_e7d2e4e616f84409bea18325f7500352 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e7d2e4e616f84409bea18325f7500352(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
